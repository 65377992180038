import React from 'react';

import { MarketingSafeSignupLandingPage } from 'components/pages';
import Layout from 'components/global/layout';
import { SEO_START_YOUR_TRIAL, SEO_START_YOUR_TRIAL_TODAY } from 'consts/copy';

const MarketingSafeFreeTrialSignupPage = () => (
  <Layout
    nav={{
      isTransparent: true,
    }}
    seo={{
      title: `Treat Fine Lines, Dark Spots, and More | Agency Custom Skincare | ${SEO_START_YOUR_TRIAL}`,
      description: `Formulas customized by a skincare expert for your skin’s future. Skincare that adapts as your skin evolves. ${SEO_START_YOUR_TRIAL_TODAY}`,
      ogTitle: `Customized Future-Defining Skincare | Agency | ${SEO_START_YOUR_TRIAL}`,
      ogDescription: `Unique, personalized formulas designed by a skincare expert who knows your unique skin. ${SEO_START_YOUR_TRIAL_TODAY}`,
    }}
  >
    <MarketingSafeSignupLandingPage />
  </Layout>
);

export default MarketingSafeFreeTrialSignupPage;
